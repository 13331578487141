import React, { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router"
import ProductCard from "./ProductCard"
import OrderStatusModal from "./components/OrderStatusModal"
import StoreBasket from "../ColteneStore/StoreBasket"
import Breadcrumb from "../common/Breadcrumb"
import SimpleButton from "../common/SimpleButton"
import ClickOutside from "../common/ClickOutside"
import LoadingSpinner from "../common/LoadingSpinner"
import SearchWithFilter from "../common/SearchWithFilter"
import EmptyPageResults from "../common/EmptyPageResults"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import WrappedTabs from "../common/WrappedTabs"
import { CaretDownIcon } from "../../icons"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"
import { clearBasket, loadStoreProducts } from "../../actions/colteneStore"
import { clearBackNavigationState } from "../../actions/backNavigationState"
import { getUserSubscriptions } from "../../utils/requests/productsAPI"
import "./coltenestore.scss"

const sortingOptions = [
  {
    key:"amount_asc",
    label: "coltene_store.amount_asc",
  },
  {
    key:"amount_desc",
    label: "coltene_store.amount_desc",
  }
]

const ColteneStore = (props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const canGoBack = location.state && location.state.withBackNavigation;
  const { basket, isLoading } = props
  const [showStoreBasket, setShowStoreBasket] = useState(false)
  const [showSuccessOrderModal, setShowSuccessOrderModal] = useState(false)
  const [successOrderId, setSuccessOrderId] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [selectedSortingOption, setSelectedSortingOption] = useState(null)
  const [searchParams, setSearchParams] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [products, setProducts] = useState([])
  const [activeSubscriptions, setActiveSubscriptions] = useState([])
  const [currentSearchPage, setCurrentSearchPage] = useState(1)
  const [selectedProductType, setSelectedProductType] = useState([])
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false)
  const [activeTab, setActiveTab] = useState(null)
  const productTypes = [t("coltene_store.physical_product"), t("coltene_store.subscriptions")]
  const ongoingSubscriptionStatuses = ["active", "trialing", "past_due"]

  const tabs = useMemo(() => ([
    {
      id: 0,
      name: t("coltene_store.products"),
    },
    {
      id: 1,
      name: t("coltene_store.subscriptions"),
    },
  ]), [])

  useLayoutEffect(() => {
    const activeIndex = props.backNavigationState?.activeIndex
    const currentTab = tabs.find((tab) => tab.id === activeIndex) || tabs[0]
    setActiveTab(currentTab)

    if (activeIndex) {
      props.actions.clearBackNavigationState()
    }
  },[tabs])

  useEffect(() => {
    setShowSpinner(isLoading)
  }, [isLoading])

  useEffect(() => {
    setProducts(props.products)
  }, [props.products])

  useEffect(() => {
    getUserSubscriptions()
      .then((subscriptions) => {
        const filteredActiveSubscriptions = subscriptions?.filter((subs) => ongoingSubscriptionStatuses.includes(subs.status))?.map(s => s.product?.id)
        setActiveSubscriptions(filteredActiveSubscriptions  || [])
      })
      .finally(() => {
        setSubscriptionsLoaded(true)
      })
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const status = queryParams.get("order_status")
    const orderId = queryParams.get("order")

    if (status === "successful") {
      props.actions.clearBasket()
      history.replace({ search: "" })
      setShowSuccessOrderModal(true)
      setSuccessOrderId(orderId)
    }
  }, [location])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setCurrentSearchPage(1)
          handleLoadProducts(true)
        }

        setShouldApplyFilters(true)
      },
      searchParams ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [searchParams, selectedProductType, selectedSortingOption])

  useEffect(() => {
    if (currentSearchPage && currentSearchPage > 1) {
      handleLoadProducts(false)
    }
  }, [currentSearchPage])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  const handleChangeTab = (tabKey) => {
    const tab = tabs.find((tab) => tab.id === tabKey)
    setActiveTab(tab)
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setSearchParams(value)
    } else if (value?.length === 0 && searchParams?.length > 0) {
      setShowSpinner(true)
      setSearchParams(null)
    }
  }

  const handleLoadProducts = (withReset) => {
    let queryParams = createQueryParams()

    queryParams = {
      ...queryParams,
      startPage: withReset ? 1 : currentSearchPage,
    }

    props.actions.loadStoreProducts(queryParams, withReset).then(() => setIsFirstLoad(false))
  }

  const createQueryParams = () => {
    let queryParams = {}

    if (searchParams) {
      queryParams = { ...queryParams, title: searchParams }
    }

    if (selectedProductType.length === 1) {
      queryParams = { ...queryParams, digital: selectedProductType.includes(productTypes[1]) }
    }

    if (selectedSortingOption) {
      queryParams = { ...queryParams, orderBy: selectedSortingOption.key.split("_")[0], order: selectedSortingOption.key.split("_")[1] }
    }

    return queryParams
  }

  const handleLoadMore = () => {
    setCurrentSearchPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setSelectedProductType([])
  }

  const onSelectOptionInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectSortingOption = (option, toggleVisibility) => {
    setSelectedSortingOption(option)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectSortInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className="select-input">
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectOptionInput(isComponentVisible, toggleVisibility)}>
        <div className="text-wrapper d-flex flex-align-center h-100">{selectedSortingOption ? t(`${selectedSortingOption.label}`) : ""}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectSortOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {sortingOptions.map((sortingOption, index) => (
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (sortingOption === selectedSortingOption ? " selected-option" : "")}
          onClick={() => selectSortingOption(sortingOption, toggleVisibility)}
        >
          {t(`${sortingOption.label}`)}
        </div>
      ))}
    </div>
  )

  return (
    <div className="coltene-store-wrapper">
      <Breadcrumb path={window.location.pathname} withBackNavigation={canGoBack}/>
      <DashboardHeader headerText={t("nav.coltene-store")} />
      <WrappedTabs tabs={tabs} activeTab={activeTab?.id} changeTab={handleChangeTab} />
      <div className="coltene-store-header">
        <SearchWithFilter
          onSearch={handleSearch}
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={() => handleResetFilters()}
          showCounterBadge={selectedProductType?.length > 0}
          counterBadge={selectedProductType?.length}
        />
        <div className="dropdown-area">
          <div className="sorting-label">
          	{t("coltene_store.sorting_options.sort_by")}
          </div>
          <ClickOutside
          	itemRef="click-outside-wrapper"
          	eventItem={selectSortInput}
          	toDisplayItem={selectSortOptions}
          />
        </div>
      </div>
      <div className="product-container">
        {products.filter(p => {
          if (activeTab.id === 1) {
            return p.category === "Subscriptions"
          }
          return p.category !== "Subscriptions" 
        }).flatMap((product) => {
          return product.prices.map(price => (
            <ProductCard
              key={price.id}
              product={product}
              price={price}
              numInBasket={basket[product.id]}
              setShowStoreBasket={() => setShowStoreBasket(true)}
              activeSubscriptions={subscriptionsLoaded && activeSubscriptions}
            />
          ))
        })}
      </div>
      {!showSpinner && !isFirstLoad && products.length === 0 && 
        <EmptyPageResults 
          title={t("coltene_store.cart.no_products_found")} 
        />
      }
      {canLoadMore && (
        <div className="load-more-wrapper d-flex flex-justify-center">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t("buttons.load_more")}
          </SimpleButton>
        </div>
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("coltene_store.product_type"),
              type: FilterType.multiSelect,
              dataset: productTypes,
              input: selectedProductType,
              output: (filterProductTypes) => {
                setSelectedProductType(filterProductTypes)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showSuccessOrderModal && 
        <OrderStatusModal
          onClose={() => setShowSuccessOrderModal(false)}
          orderId={successOrderId}
        />
      }
      <StoreBasket 
        toggle={showStoreBasket} 
        onToggle={() => setShowStoreBasket(false)} 
      />
    </div>
  )
}

function stateToProps({ colteneStore, backNavigationState }) {
  return {
    products: colteneStore?.products || [],
    isLoading: colteneStore?.isLoading,
    basket: colteneStore?.basket || {},
    canLoadMore: colteneStore?.canLoadMore,
    backNavigationState
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadStoreProducts,
        clearBasket,
        clearBackNavigationState,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(ColteneStore)
