import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CycleDetailsModal from "../common/CycleDetailsModal";
import TooltipedIcon from "../common/TooltipedIcon";
import { toast } from "../common/Toast"
import TemperatureIcon from "../../icons/temperature.svg";
import PressureIcon from "../../icons/pressure.svg";
import HoldTimeIcon from "../../icons/hold_time.svg";
import { AddNoteIcon, SkipIcon} from "../../icons";
import { getProductCyclePrintout } from "../../utils/requests/productsAPI"
import { getCycleDetails } from "../../utils/requests/trackingAPI";
import { useAppContext } from "../../libs/contextLib";
import { convertDateWithTandZ } from "../../utils/functions";
import { keyboardEvents } from "../../utils/keyboardEvents";

const Table = (props) => {
  const { t } = useTranslation()
  const {
    reports,
    userProducts,
    totalReports,
    currentPage,
    apertureSerials,
    canAccessInstrumentTracking,
    isLoading,
    handleOpenAddNote,
    onChangeCurrentPage,
    setShowSpinner
  } = props
  const { showFeatureDev } = useAppContext()
  const [cycleDetails, setCycleDetails] = useState({})
  const [cycleDetailsDeviceData, setCycleDetailsDeviceData] = useState({})
  const [showCycleDetails, setShowCycleDetails] = useState(false)
  const reportsPerPage = 20
  const pageSkip = 10
  const pageNumbers = []
  const isG4PlusAPIEnabled = process.env.REACT_APP_G4PLUS_API_TOGGLE === "on"

  for (let i = 1; i <= Math.ceil(totalReports / reportsPerPage); i++) {
    pageNumbers.push(i);
  }

  const skipForward = () => {
    if (isLoading) {
      return
    }
    const skipTo = Number(currentPage) + pageSkip
    const totalPages = pageNumbers.length

    onChangeCurrentPage(skipTo < totalPages ? skipTo : totalPages)
  }

  const skipBackward = () => {
    if (isLoading) {
      return
    }

    const skipTo = Number(currentPage) - pageSkip
    const startPage = 1

    onChangeCurrentPage(skipTo > startPage ? skipTo : startPage)
  }

  const renderPageNumbers = () => {
    const pageNumbersToRender = [];

    const maxPages = 10;
    const totalPages = pageNumbers.length;
    const selectedPage = Number(currentPage);

    let rangeStart = selectedPage <= 2 ? 1 : selectedPage - 1;
    let rangeEnd = selectedPage <= 2
      ? totalPages < maxPages
        ? totalPages
        : maxPages
      : selectedPage + 8;

    if (totalPages - selectedPage < 7) {
      rangeStart = Math.max(1, totalPages - maxPages + 1);
      rangeEnd = totalPages;
    }

    if (rangeStart > 1) {
      pageNumbersToRender.push(
        <div key="start" className={"jump-navigation-wrapper"}>
          <span className={"dots"}>...</span>
          <SkipIcon
            className="skip-arrow arrow-left"
            onClick={() => skipBackward()}
          />
        </div>
      );
    }
    
    for (let i = rangeStart; i <= rangeEnd; i++) {
      pageNumbersToRender.push(
        <span
          key={i}
          className={selectedPage !== i ? "page-number" : "page-number-active"}
          id={i}
          onClick={handleClick}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => e.key === keyboardEvents.ENTER && handleClick(e)}
        >
          {i}
        </span>
      );
    }

    if (rangeEnd < totalPages) {
      pageNumbersToRender.push(
        <div key="end" className={"jump-navigation-wrapper"}>
          <span className={"dots"}>...</span>
          <SkipIcon
            className="skip-arrow" 
            onClick={() => skipForward()}
          />
        </div>
      );
    }

    return pageNumbersToRender;
  }

  function handleClick(event) {
    onChangeCurrentPage(event.target.id);
  }

  const openCyclePrintout = (item) => {
    if (isLoading) {
      return
    }

    setShowSpinner(true)

    if (!item?.printout_file_name?.length > 0 && !item?.records_uuid?.length > 0) {
      setShowSpinner(false)
      return
    }

    setCycleDetailsDeviceData({
      model: item?.model,
      cycleNumber: item?.cycle_number,
      cycleStartTime: item?.date,
      deviceSerialNumber: item?.machine_id,
    })

    if (showFeatureDev && item?.records_uuid && isG4PlusAPIEnabled) {
      getCycleDetails(item?.records_uuid, item?.machine_id)
        .then((res) => {
          setCycleDetails(res)

          setShowCycleDetails(true)
        })
        .finally(() => {
          setShowSpinner(false)
        })
    } else {
      if (apertureSerials?.includes(item?.machine_id) && !canAccessInstrumentTracking) {
        toast.error(t("error.forbidden"))

        return
      }
      
        getProductCyclePrintout(item?.printout_file_name)
        .then((res) => {
          const data = res.data;
          if (!data || !data.serial_number || !data.model) {
            toast.error(t("report-modal.no_data"));
            return;
          }
      
          setCycleDetails(data);
          setShowCycleDetails(true);
        })
        .catch((error) => {
          toast.error(t(`error.${error.response.data.code}`));
        })
        .finally(() => {
          setShowSpinner(false);
        });
    }
  }
  
  function normalizeValue(value) {
    if (value === null || value === undefined) {
      return ''
    }
    
    const lowercaseValue = value.toLowerCase()
    if (lowercaseValue === 'na') {
      return 'n/a'
    }

    return lowercaseValue.replaceAll(" ","_")
  }

  const handleNewNote = (item) => {
    handleOpenAddNote(
      item?.machine_id, 
      item?.cycle_number, 
      item?.is_g4_plus, 
      item?.printout_file_name, 
      (item.date ? item.date : item?.isoDate), 
      item?.id,
      item?.model_id,
      item?.ped_source
    )
  }

  return (
    <div className="reports-wrapper">
      <div className="table-view">
        <table className="table" id="table-data-full">
          <thead>
            <tr>
              <th>{t("reports.date")}</th>
              <th>{t("reports.machine")}</th>
              <th>
                <div className="max-width-cell">
                  {t("reports.cycle")} #
                </div>
              </th>
              <th>{t("reports.cycle_parameters")}</th>
              <th>{t("reports.contents")}</th>
              <th>{t("reports.printout_verification")}</th>
              <th>{t("reports.ci_pcd")}</th>
              <th>{t("reports.external_ci")}</th>
              <th>{t("reports.bi_test")}</th>
              <th>{t("reports.test_cycles")}</th>
              <th>{t("reports.user_id")}</th>
              <th>{t("reports.notes")}</th> 
              <th>{""}</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {reports?.map(r => {
              if (r.date == null) {
                return {
                  ...r,
                  date: convertDateWithTandZ(r.isoDate).replace("  ", " ")
                }
              }
              return r;
            }).sort((a, b) => {
              return (new Date(b.date) - new Date(a.date));
            }).map((item, index) => {
                return (
                  <tr key={index} className="normal-table-content">
                    <td className="normal-table-content2">
                      <div className="max-width-cell">
                        {showFeatureDev ? (item?.date) : ((item?.date)?.split(" ")[0])}
                      </div>
                    </td>
                    <td>
                      {item?.model || item?.deviceNickName ? (
                        <>
                          <div>{item?.model || item?.deviceNickName}</div>
                          <div>{item?.machine_id}</div>
                        </>
                      ) : (
                        <div>{item?.machine_id}</div>
                      )}
                    </td>
                    <td 
                      className={"bold-table-content cycle-number" + (!item?.printout_file_name?.length > 0 && !item?.records_uuid?.length > 0 ? " cycle-number-disabled" : "") } 
                      onClick={() => openCyclePrintout(item)}
                      tabIndex={!item?.printout_file_name?.length > 0 && !item?.records_uuid?.length > 0 ? -1 : 0}
                      onKeyDown={(event) => {
                        if (event.key === keyboardEvents.ENTER && (item?.printout_file_name?.length > 0 || item?.records_uuid?.length > 0)) {
                          openCyclePrintout(item)
                        }
                      }}
                    >
                      {item?.cycle_number}
                    </td>
                    <td className="normal-table-content2">
                      <div className="table-data">
                        {t([`reports.${normalizeValue(item?.cycle_data?.cycle_name)}`, item?.cycle_data?.cycle_name])}
                      </div>
                      <div className="params-wrapper d-flex">
                        <div className="table-data">
                          <div className="table-image">
                            <img src={HoldTimeIcon} alt="hold-time" />
                          </div>
                          <div className="table-value">
                            {(item?.cycle_data?.hold_time === null  || !(parseFloat(item?.cycle_data?.hold_time) !== 0)) ? t("reports.n/a") : `${(item?.cycle_data?.hold_time)}${t(`reports.${item?.cycle_data?.hold_time_unit || "min"}`)}`}
                          </div>
                        </div>
                        <div className="table-data">
                          <div className="table-image">
                            <img src={TemperatureIcon} alt="temperature" />
                          </div>
                          <div className="table-value">
                            {((item?.cycle_data?.hold_temperature === null || !(parseFloat(item?.cycle_data?.hold_temperature) !== 0)) ? t("reports.n/a") : (item?.cycle_data?.hold_temperature) +
                              "\u00B0" +
                              (item?.cycle_data?.hold_temperature_unit ? item?.cycle_data?.hold_temperature_unit : ""))}
                          </div>
                        </div>
                        {!item?.model.toLowerCase().includes("hydrim") && 
                          <div className="table-data">
                            <div className="table-image">
                              <img src={PressureIcon} alt="pressure" />
                            </div>
                            <div className="table-value">
                              {((item?.cycle_data?.hold_pressure === null || !(parseFloat(item?.cycle_data?.hold_pressure) !== 0)) ? t("reports.n/a") : (item?.cycle_data?.hold_pressure) +
                                (item?.cycle_data?.hold_pressure_unit ? item?.cycle_data?.hold_pressure_unit : ""))}
                            </div>
                          </div>
                        }
                      </div>                      
                    </td>
                    <td className="capitalize">
                      <ul className="list-content max-width-cell" style={{ padding: 0, margin: 0 }}>
                        {Array.isArray(item?.general_contents_count) && item?.general_contents_count?.length > 0
                          ? item?.general_contents_count?.map((generalContent, index) => (
                              <li key={`${generalContent.contents}_${index}`}>
                                {t([`reports.${normalizeValue(generalContent.contents)}`, generalContent.contents])}: {generalContent.contents_count}
                              </li>
                            ))
                          : item?.general_contents?.map((generalContent, index) => <li key={`${generalContent}_${index}`}>{t([`reports.${normalizeValue(generalContent)}`, generalContent])}</li>)}
                      </ul>
                    </td>
                    <td className="capitalize">
                      {item?.screen_readout === "pass" ? t("labels.accept") : item?.screen_readout === "fail" ? t("labels.reject") : ""}
                    </td>
                    <td className="capitalize">{t([`reports.${normalizeValue(item?.ci_pcd)}`, normalizeValue(item?.ci_pcd)])}</td>
                    <td className="capitalize">{t([`reports.${normalizeValue(item.hasOwnProperty("chemical_indicator") ? item?.chemical_indicator : item?.ci_external)}`, normalizeValue(item.hasOwnProperty("chemical_indicator") ? item?.chemical_indicator : item?.ci_external)])}</td>
                    <td className="capitalize">{t([`reports.${normalizeValue(item?.bi_test)}`, normalizeValue(item?.bi_test)])}</td>
                    {/* <td>{item?.test_cycle?.air_removal}</td> */}
                    <td className="capitalize">
                      {Object.keys(item?.test_cycle || {}).length === 0
                          ? null 
                          : Object.values(item.test_cycle).every(result => result === "") 
                            ? null 
                            : Object.values(item.test_cycle).some(result => result === "pass")
                              ? t("reports.pass")
                              : Object.values(item.test_cycle).some(result => result === "fail")
                                ? t("reports.fail")
                                : t("reports.n/a")
                        }
                    </td>
                    <td className="capitalize">
                      <div className="max-width-cell">
                        {t("reports.user_begin")}: {t([`reports.${normalizeValue(item?.initial?.user_begin)}`, item?.initial?.user_begin])}{" "}
                      </div>
                      <div className="max-width-cell">
                        {t("reports.user_end")}: {t([`reports.${normalizeValue(item?.initial?.user_end)}`, item?.initial?.user_end])}
                      </div>
                    </td>
                    <td className="capitalize">{item?.notes?.join(", ")}</td>
                    <td className="tooltip-content">
                      {userProducts[item.machine_id]?.association_active && (
                        <TooltipedIcon
                          icon={
                          	<AddNoteIcon
                          		onClick={() => handleNewNote(item)}
                          		tabIndex={0}
                            	onKeyDown={(e) => e.key === keyboardEvents.ENTER && handleNewNote(item)}
                          	/>
                          }
                          tooltipText={t("reports.add_note")}
                        />)
                      }
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {reports?.length !== 0 &&
          <div className="page-numbers">
            <div
              className="prev-next"
              onClick={() => {
                onChangeCurrentPage(1);
              }}
              tabIndex={0}
              role="button"
              onKeyDown={(e) => e.key === keyboardEvents.ENTER && onChangeCurrentPage(1)}
            >
              {t("labels.first")}
            </div>
            <div className="num">{renderPageNumbers()}</div>
            <div
              className="prev-next"
              onClick={() => {
                onChangeCurrentPage(pageNumbers.length);
              }}
              tabIndex={0}
              role="button"
              onKeyDown={(e) => e.key === keyboardEvents.ENTER && onChangeCurrentPage(pageNumbers.length)}
            >
              {t("labels.last")}
            </div>
          </div>
        }
      </div>
      {showCycleDetails &&
        <CycleDetailsModal
          deviceData={cycleDetailsDeviceData}
          cycleDetails={cycleDetails}
          closeModal={() => {
            setShowCycleDetails(false)
            setCycleDetails({})
            setCycleDetailsDeviceData({})
          }}
        />
      }
    </div>
  );
};

export default Table;
