import React, { useState, useLayoutEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ClickOutside from "../common/ClickOutside"
import PageModal from "../common/PageModal"
import SimpleButton from '../common/SimpleButton'
import GoogleAddressInput from "../common/GoogleAddressInput"
import ToggleSwitch from "../common/ToggleSwitch"
import ConfirmationModal from "../common/ConfirmationModal"
import { toast } from "../common/Toast"
import {
  loadCompanyRoles,
  createCompany,
  putCompany,
} from "../../actions/companies"
import { CaretDownIcon } from "../../icons"
import countries from "../../utils/countries.json"
import { companiesWritePermission } from "../../utils/permissionValidation"
import "./add-edit-company.scss"

const AddEditCompany = (props) => {
  const { t } = useTranslation()
  const { selectedCompany, companyRoles, closeModal, showSpinner } = props
  const [companyName, setCompanyName] = useState("")
  const [companyStatus, setCompanyStatus] = useState("approved")
  const [country, setCountry] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [stateProvince, setStateProvice] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [isDentistCompany, setIsDentistCompany] = useState(true)
  const [haveError, setHaveError] = useState({})
  const [showDisablingConfirmationModal, setShowDisablingConfirmationModal] = useState(false)

  useLayoutEffect(() => {
    if (selectedCompany) {
      setCompanyName(selectedCompany.companyName)
      setCompanyStatus(selectedCompany.status)
      setCountry(selectedCompany.country || "")
      setAddress(selectedCompany.officeAddressOne || "")
      setCity(selectedCompany.city || "")
      setStateProvice(selectedCompany.stateProvinceRegion || "")
      setZipCode(selectedCompany.zipPostalCode || "")

      props.actions.loadCompanyRoles(selectedCompany.id)
    }
  },[selectedCompany])

  useLayoutEffect(() => {
    if (companyRoles.length > 0) {

      const hasDentistRoles = companyRoles.find(r => r.name?.toLowerCase()?.includes("dentist"))
      setIsDentistCompany(hasDentistRoles)
    }
  }, [companyRoles])

  const companyHeaderContent = () => {
    return (
      <div className="status-wrapper">
        <div className="label">{t("company.status")}</div>
        <ToggleSwitch
          checked={companyStatus === "approved"}
          onChange={() => setCompanyStatus(prevValue => prevValue === "approved" ? "disabled" : "approved")}
          isDisabled={companyStatus === "pending" || isDentistCompany || showSpinner}
        />
        <div className="status">{t(`company.${companyStatus}`)}</div>
      </div>
    )
  }

  const companyFooterContent = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button" 
          onClick={() => closeModal()}
        >
          {t('buttons.cancel')}
        </SimpleButton>
        <SimpleButton
          className="submit-button"
          onClick={() => handleAddEditCompany()}
          disabled={showSpinner || hasSameInput()}
          requiredPermission={companiesWritePermission}
        >
          {t('labels.save')}
        </SimpleButton>
      </>
    )
  }

  const hasSameInput = () => {
    return (
      selectedCompany &&
      companyName === selectedCompany.companyName &&
      companyStatus === selectedCompany.status &&
      address === selectedCompany.officeAddressOne &&
      city === selectedCompany.city &&
      stateProvince === selectedCompany.stateProvinceRegion &&
      zipCode === selectedCompany.zipPostalCode
    )
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectCountry = (country, toggleVisibility) => {
    setCountry(country)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectCountryInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {country ? countries?.find((c) => c.code === country)?.name : t("users-management.select-country")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCountryOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {countries?.map((countryOption, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (countryOption["code"] === country ? " selected-option" : "")} 
          key={index}
          onClick={() => selectCountry(countryOption["code"], toggleVisibility)}
        >
          {countryOption["name"]}
        </div>
      ))
      }
    </div>
  )

  const isValidInput = () => {
    let errors = {}
    let isValid = true

    if (!companyName.trim()) {
      errors = { ...errors, companyName: true }
      isValid = false
    }

    if (!country.trim()) {
      errors = { ...errors, country: true }
      isValid = false
    }

    if (!address.trim()) {
      errors = { ...errors, address: true }
      isValid = false
    }

    if (!city.trim()) {
      errors = { ...errors, city: true }
      isValid = false
    }

    if (!stateProvince.trim()) {
      errors = { ...errors, stateProvince: true }
      isValid = false
    }

    if (!zipCode.trim()) {
      errors = { ...errors, zipCode: true }
      isValid = false
    }

    setHaveError(errors)
    return isValid
  }

  const handleAddEditCompany = () => {
    if (!isValidInput()) {
      toast.error(t("error.fill_all_fields"))
      return
    }

    if (selectedCompany && companyStatus === "disabled") {
      setShowDisablingConfirmationModal(true)
      return
    }

    handleRequest()
  }

  const handleRequest = () => {
    const bodyParams = {
      companyName: companyName.trim(),
      email: selectedCompany?.email || "",
      country: country.trim(),
      officeAddressOne: address.trim(),
      city: city.trim(),
      stateProvinceRegion: stateProvince.trim(),
      zipPostalCode: zipCode.trim(),
      status: companyStatus,
    }

    if (selectedCompany) {
      props.actions.putCompany(selectedCompany.id, bodyParams)
    } else {
      props.actions.createCompany(bodyParams)
    }

    closeModal()
  }

  return (
    <>
      <PageModal
        toggle
        onToggle={() => closeModal()}
        className="add-edit-company-modal"
        title={selectedCompany ? t("company.edit_company") : t("company.add_company")}
        headerContent={companyHeaderContent()}
        footerContent={companyFooterContent()}
      >
        <div className="add-edit-company-modal-content">
          <div className="row-wrapper">
            <div className="input-wrapper">
              <p className="input-option">{t('company.name')}*</p>
              <input 
                type="text" 
                className={"name-input" + (haveError["companyName"] ? " has-error" : "")}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="input-wrapper">
              <p className="input-option">{t('company.email')}</p>
              <input 
                type="text" 
                className="name-input"
                value={selectedCompany?.email || ""}
                disabled
              />
            </div>
          </div>
          <div className="row-wrapper">
            <div className="input-wrapper">
              <p className="input-option">{t('company.country')}*</p>
              <ClickOutside
                itemRef="click-outside-wrapper"
                className={haveError["country"] ? " has-error" : ""}
                eventItem={selectCountryInput}
                toDisplayItem={selectCountryOptions}
              />
            </div>
          </div>
          <div className="row-wrapper">
            <GoogleAddressInput
              country={country}
              address={address}
              handleSetAddress={(e) => setAddress(e)}
              city={city}
              handleSetCity={(e) => setCity(e)}
              stateProvince={stateProvince}
              handleSetStateProvince={(e) => setStateProvice(e)}
              zipCode={zipCode}
              handleSetZipCode={(e) => setZipCode(e)}
              errors={haveError}
            />
          </div>
        </div>
      </PageModal>
      {showDisablingConfirmationModal && (
        <ConfirmationModal
          className="invalidate-confirmation-modal"
          onToggle={() => setShowDisablingConfirmationModal(false)}
          message={t("company.accept_disable_confirmation")}
          onCancel={()=> setShowDisablingConfirmationModal(false)}
          onAccept={() => {
            handleRequest()
            setShowDisablingConfirmationModal(false)
          }}
          requiredPermission={companiesWritePermission}
        >
          <div className="invalidate-confirmation-wrapper">
            <div className="disclaimer">
              <p>
                {t("company.accept_disable_disclaimer")}
              </p>
            </div>
          </div>
        </ConfirmationModal>
      )}
    </>
  )
}

function stateToProps({ companies }) {
  return {
    companyRoles: companies?.roles || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createCompany,
        putCompany,
        loadCompanyRoles,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps,dispatchToProps)(AddEditCompany)
