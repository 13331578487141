import { Auth } from "aws-amplify"
import axios from "axios"
import config from "../config"
import { _baseGetFetch, _baseGetFetchNoLang, _basePutFetch, ApiEnum, _basePostFetch, _baseDeleteFetch, _basePatchFetch, _baseDeleteWithBodyFetch } from "./baseFetch"
const baseUrl = "https://5d9996a5-ccd5-41c5-b8d2-8ab459b47898.mock.pstmn.io"
const xApiKey = "PMAK-607c5fc46c0c5a0063428897-38023b2d4d9c640b8fb7cd0b1701a15c1a"
const path = "/products"
const maxDownloadLimit = 1000

export const getProductTypes = () => {
  return _baseGetFetch(`${path}/types`)
    .then((res) => res)
    .catch( e => { 
      console.log(e.message)
      return {}
    })
}

export const getProductSummary = (serialNumber, queryParams) => {
  return _baseGetFetch(`/products/${serialNumber}`, queryParams)
}

export const getEndUserProductSummary = (userUuid, serialNumber, queryParams) => {
  return _baseGetFetch(`/users/${userUuid}/products/${serialNumber}`, queryParams)
}

export const getProductCycles = (serialNumber, queryParams) => {
  return _baseGetFetch(`/products/${serialNumber}/cycles`, queryParams)
}

export const getProductCyclesFaultsNotes = (id, queryParams) => {
  return _baseGetFetch(`/cycles/faults/${id}/notes`, queryParams)
}

export const getProductCyclesFaultsNotesBySn = (sn, queryParams) => {
  return _baseGetFetch(`/products/${sn}/cycles/faults/notes`, queryParams)
}

export const getStoreProducts = async (queryParams) => {
  return _baseGetFetchNoLang(`/users/current/store/products`, { ...queryParams, maxResults: process.env.REACT_APP_PAGINATION_SIZE }, ApiEnum.store)
}

export const getShippingAddress = async () => {
  return _baseGetFetchNoLang(`/users/current/shippingaddress`, undefined, ApiEnum.store)
}

export const getStoreProductById = async (id) => {
  return _baseGetFetchNoLang(`/users/current/store/products/${id}`, undefined, ApiEnum.store)
}

export const getUserOrders = async (queryParams, limit = process.env.REACT_APP_PAGINATION_SIZE) => {
  //TODO: change to actual endpoint and structure

  return _baseGetFetch(`/users/current/store/orders`, { ...queryParams, limit: limit }, ApiEnum.store)
}

export const getUserOrderById = async (id) => {
  return _baseGetFetch(`/users/current/store/orders/${id}`, undefined, ApiEnum.store)
}

export const getUserOrderDocuments = async (id) => {
  return _baseGetFetch(`/users/current/store/orders/${id}/orderDocuments`, undefined, ApiEnum.store)
}

export const getUserOrderDocumentS3Location = async (orderId, documentId) => {
  return _baseGetFetch(`/users/current/store/orders/${orderId}/orderDocuments/${documentId}/url`, undefined, ApiEnum.store)
}

export const getUserSubscriptions = async (queryParams) => {
  return _baseGetFetch("/users/current/store/subscriptions", queryParams, ApiEnum.store)
}

export const getUserSubscriptionById = async (id) => {
  return _baseGetFetch(`/users/current/store/subscriptions/${id}`, undefined, ApiEnum.store)
}

export const requestUserRefund = async (id) => {
  return _basePutFetch(`/users/current/store/orders/${id}/refund`, undefined, ApiEnum.store)
}

export const putSubscription = async (id, bodyParams) => {
  return _basePutFetch(`/users/current/store/subscriptions/${id}`, bodyParams, ApiEnum.store)
}

export const deleteUserSubscription = async (id) => {
  return _baseDeleteFetch(`/users/current/store/subscriptions/${id}`, undefined, ApiEnum.store)
}

export const getStoreCheckoutLink = async (body) => {
  return _basePostFetch(`/users/current/store/checkout`, body, ApiEnum.store)
}

export const getAdminStoreProducts = async (queryParams) => {
  return _baseGetFetchNoLang(`/store/products`, { ...queryParams, maxResults: process.env.REACT_APP_PAGINATION_SIZE }, ApiEnum.store)
}

export const getAdminStoreProductById = async (productId) => {
  return _baseGetFetchNoLang(`/store/products/${productId}`, undefined, ApiEnum.store)
}

export const getAdminStoreCountries = async () => {
  return _baseGetFetch(`/store/countries`, undefined, ApiEnum.store)
}

export const getAdminStorePricePlansForDigital = async () => {
  return _baseGetFetch(`/store/plans`, undefined, ApiEnum.store)
}

export const getAdminStoreServices = async () => {
  return _baseGetFetch(`/store/services`, undefined, ApiEnum.store)
}

export const getAdminStoreProductCategories = async () => {
  return _baseGetFetch(`/store/products/categories`, undefined, ApiEnum.store)
}

export const postAdminProduct = async (body) => {
  return _basePostFetch(`/store/products`, body, ApiEnum.store)
}

export const putAdminProduct = async (productId, body) => {
  return _basePutFetch(`/store/products/${productId}`, body, ApiEnum.store)
}

export const postAdminProductPrice = async (body, id) => {
  return _basePostFetch(`/store/products/${id}/prices`, body, ApiEnum.store)
}

export const patchAdminProductPrice = async (body, productId, priceId) => {
  return _basePatchFetch(`/store/products/${productId}/prices/${priceId}`, body, ApiEnum.store)
}

export const deleteAdminProductPrice = async (productId, priceId) => {
  return _baseDeleteFetch(`/store/products/${productId}/prices/${priceId}`, undefined, ApiEnum.store)
}

export const getAdminImageSignedUrl = async (file) => {
  return _baseGetFetch(`/store/products/images/${file}`, undefined, ApiEnum.store)
}

export const putAdminProductImage = async (url, data, fileType) => {
  return await axios.put(url, data, {
    headers: {
      "Content-Type": `image/${fileType}`,
    },
  })
}

export const putPublishAdminProduct = async (id) => {
  return _basePutFetch(`/store/products/${id}/publish`, undefined, ApiEnum.store)
}

export const putUnpublishAdminProduct = async (id) => {
  return _basePutFetch(`/store/products/${id}/unpublish`, undefined, ApiEnum.store)
}

export const deleteAdminProduct = async (id) => {
  return _baseDeleteFetch(`/store/products/${id}`, undefined, ApiEnum.store)
}

export const getAdminSubscriptionHistory = async (queryParams, limit = process.env.REACT_APP_PAGINATION_SIZE) => {
  return _baseGetFetch(`/store/subscriptions`, { ...queryParams, limit: limit }, ApiEnum.store)
}

export const getAdminSubscriptionHistoryCsv = async (queryParams) => {
  return _baseGetFetch(`/store/subscriptions/download`, { ...queryParams, limit: maxDownloadLimit }, ApiEnum.store)
}

export const getCustomerOrderById = async (cognitoUuid, orderId) => {
  return _baseGetFetch(`/users/${cognitoUuid}/store/orders/${orderId}`, undefined, ApiEnum.store)
}

export const getAdminOrderHistory = async (queryParams, limit = process.env.REACT_APP_PAGINATION_SIZE) => {
  return _baseGetFetch(`/store/orders`, { ...queryParams, limit: limit }, ApiEnum.store)
}

export const getAdminOrderHistoryCsv = async (queryParams) => {
  return _baseGetFetch(`/store/orders/download`, { ...queryParams, limit: maxDownloadLimit }, ApiEnum.store)
}

export const getAdminOrderStatuses = async () => {
  return _baseGetFetch(`/store/orders/statuses`, undefined, ApiEnum.store)
}

export const acceptAdminRefund = async (id) => {
  return _basePutFetch(`/store/orders/${id}/refundapproved`, undefined, ApiEnum.store)
}

export const declineAdminRefund = async (id) => {
  return _basePutFetch(`/store/orders/${id}/refundrejected`, undefined, ApiEnum.store)
}

export const completeAdminRefund = async (id) => {
  return _basePutFetch(`/store/orders/${id}/refundcompleted`, undefined, ApiEnum.store)
}

export const updateProductCyclesFaultsNotes = (id, queryParams) => {
  return _basePutFetch(`/cycles/faults/${id}/notes`, queryParams)
}

export const getProductCycleDetails = (serialNumber, id, queryParams) => {
  return _baseGetFetch(`/products/${serialNumber}/cycles/${id}`, queryParams)
}

export const getProductCyclePrintout = (fileName) => {
  return _baseGetFetch(`/products/cycles/details/${fileName}`, null)
}

export const getModels = () => {
  return _baseGetFetch(`${path}/models?ocp_access=1`)
}

export const getDocumentModels = () => {
  return _baseGetFetch(`${path}/document-models`, undefined, undefined)
}

/**
 * Fetches all products
 * @param {*} queryStringParameters always add 'limit: 30'(or whatever is agreed on) when fetch all product data
 * @returns
 */
export const getProducts = (queryStringParameters) => {
  return _baseGetFetch(path, queryStringParameters)
}

export function productAssociation(email, token, model_id) {
  return _basePutFetch(`${path}/online-access`, {
    email: email,
    token: token,
    model_id: model_id,
  })
}

export const getModelBySerial = (queryParams) => {
  return _baseGetFetch(`${path}/validate`, queryParams)
}

export const getProductNotificationsConfiguration = async (serialNumber) => {
  return _baseGetFetch(`${path}/${serialNumber}/notifications/emails`, undefined, undefined)
}

export const postProductNotificationConfiguration = async (serialNumber, bodyParams) => {
  return _basePostFetch(`${path}/${serialNumber}/notifications/emails`, bodyParams, undefined)
}

export const updateProductNotificationConfiguration = async (serialNumber, notificationId, email, bodyParams) => {
  return _basePutFetch(`${path}/${serialNumber}/notifications/${notificationId}/emails/${btoa(email)}`, { ...bodyParams }, undefined)
}

export const deleteProductNotificationConfiguration = async (serialNumber, email, queryPrams) => {
  return _baseDeleteFetch(`${path}/${serialNumber}/notifications/emails/${btoa(email)}`, queryPrams, undefined)
}

export const getLatestProductCycles = async (cognitoSub, serialNumber, queryParams) => {
  return _baseGetFetch(`/users/${cognitoSub}/products/${serialNumber}/cycles/latest`, queryParams, undefined)
}

export const deleteProduct = async (cognitoSub, serialNumber) => {
  return _baseDeleteFetch(`/users/${cognitoSub}/products/${serialNumber}`, undefined, undefined)
}

export const getOnlineAccessAssociations = async (queryParams) => {
  return _baseGetFetch(`${path}/online-access`, queryParams, undefined)
}

export const putOnlineAccessAssociation = async (bodyParams) => {
  return _basePutFetch(`${path}/online-access`, { ...bodyParams }, undefined)
}

export const deleteOnlineAccessAssociation = async (bodyParams) => {
  return _baseDeleteWithBodyFetch(`${path}/online-access`, { ...bodyParams }, undefined)
}

