import {
  SET_IN_BASKET,
  SET_CHECKOUT_SESSION,
  LOAD_STORE_PRODUCTS_FULFILLED,
  LOAD_STORE_PRODUCTS_PENDING,
  LOAD_STORE_NEW_PRODUCTS_PENDING,
  LOAD_STORE_NEW_PRODUCTS_FULFILLED,
  LOAD_SHIPPING_ADDRESS_PENDING,
  LOAD_SHIPPING_ADDRESS_FULFILLED,
  LOAD_SHIPPING_ADDRESS_REJECTED,
  LOAD_GOOGLE_PLACE_PENDING,
  LOAD_GOOGLE_PLACE_FULFILLED,
  LOAD_GOOGLE_PLACE_REJECTED,
  LOAD_GOOGLE_PLACE_DETAILS_PENDING,
  LOAD_GOOGLE_PLACE_DETAILS_FULFILLED,
  LOAD_GOOGLE_PLACE_DETAILS_REJECTED,
  LOAD_USER_ORDERS_FULFILLED,
  LOAD_USER_ORDERS_PENDING,
  LOAD_USER_ORDER_BY_ID_PENDING,
  LOAD_USER_ORDER_BY_ID_FULFILLED,
  LOAD_USER_ORDER_DOCUMENTS_PENDING,
  LOAD_USER_ORDER_DOCUMENTS_FULFILLED,
  LOAD_USER_SUBSCRIPTIONS_FULFILLED,
  LOAD_USER_SUBSCRIPTIONS_PENDING,
  LOAD_USER_SUBSCRIPTION_BY_ID_PENDING,
  LOAD_USER_SUBSCRIPTION_BY_ID_FULFILLED,
  LOAD_USER_SUBSCRIPTION_DETAILS_PENDING,
  LOAD_USER_SUBSCRIPTION_DETAILS_FULFILLED,
  DELETE_USER_SUBSCRIPTION_PENDING,
  DELETE_USER_SUBSCRIPTION_FULFILLED,
  DELETE_USER_SUBSCRIPTION_REJECTED,
  RENEW_SUBSCRIPTION_PENDING,
  RENEW_SUBSCRIPTION_FULFILLED,
  RENEW_SUBSCRIPTION_REJECTED,
  LOAD_STORE_PRODUCT_BY_ID_PENDING,
  LOAD_STORE_PRODUCT_BY_ID_FULFILLED,
  SET_CHECKOUT_LINK_PENDING,
  SET_CHECKOUT_LINK_FULFILLED,
  CLEAR_BASKET,
  LOAD_STORE_PRODUCTS_REJECTED,
  LOAD_STORE_NEW_PRODUCTS_REJECTED,
  LOAD_USER_ORDERS_REJECTED,
  LOAD_USER_SUBSCRIPTIONS_REJECTED,
  LOAD_USER_SUBSCRIPTION_DETAILS_REJECTED,
  SET_CHECKOUT_LINK_REJECTED,
  LOAD_STORE_PRODUCT_BY_ID_REJECTED,
  LOAD_USER_ORDER_DOCUMENTS_REJECTED,
  LOAD_USER_SUBSCRIPTION_BY_ID_REJECTED,
  LOAD_USER_ORDER_BY_ID_REJECTED,
  LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_PENDING,
  LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_FULFILLED,
  LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_REJECTED,
  LOAD_NEW_USER_ORDERS_FULFILLED,
  LOAD_NEW_USER_ORDERS_PENDING,
  LOAD_NEW_USER_ORDERS_REJECTED,
  UPDATE_REFUND_ORDER,
  CLEAR_STORE_MESSAGES,
} from "../actions/colteneStore"
import i18n from "i18next"
import { COLTENE_STORE } from "../utils/local-store-resolvers"

export default function colteneStore(state = JSON.parse(localStorage.getItem(COLTENE_STORE)) || {}, action) {
  switch (action.type) {
    case LOAD_STORE_PRODUCTS_PENDING:
    case LOAD_STORE_NEW_PRODUCTS_PENDING:
    case LOAD_USER_ORDERS_PENDING:
    case LOAD_NEW_USER_ORDERS_PENDING:
    case LOAD_USER_SUBSCRIPTIONS_PENDING:
    case LOAD_USER_SUBSCRIPTION_DETAILS_PENDING:
    case LOAD_STORE_PRODUCT_BY_ID_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      }

    case LOAD_SHIPPING_ADDRESS_PENDING:
    case SET_CHECKOUT_LINK_PENDING:
      return {
        ...state,
        isShippingPageLoading: true,
        errorMessage: null,
      }

    case LOAD_GOOGLE_PLACE_DETAILS_PENDING:
    case LOAD_GOOGLE_PLACE_PENDING:
      return {
        ...state,
        googlePlaceLoading: true,
      }

    case LOAD_USER_ORDER_DOCUMENTS_PENDING:
      return {
        ...state,
        documentsLoading: true,
      }

    case LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_PENDING:
      return {
        ...state,
        documentLocationLoading: true,
      }

    case LOAD_USER_ORDER_BY_ID_PENDING:
      return {
        ...state,
        orderLoading: true,
      }

    case LOAD_USER_SUBSCRIPTION_BY_ID_PENDING:
      return {
        ...state,
        subscriptionLoading: true,
      }

    case LOAD_STORE_PRODUCTS_FULFILLED:
      const products = state?.products || []
      return {
        ...state,
        products: [...products, ...action.payload.data],
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }

    case LOAD_STORE_NEW_PRODUCTS_FULFILLED:
      return {
        ...state,
        products: action.payload.data,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }

    case LOAD_SHIPPING_ADDRESS_REJECTED:
      return {
        ...state,
        addressShipping: {},
        isShippingPageLoading: false,
      }

    case LOAD_SHIPPING_ADDRESS_FULFILLED:
      return {
        ...state,
        addressShipping: action.payload,
        isShippingPageLoading: false,
      }

    case LOAD_GOOGLE_PLACE_REJECTED:
      return {
        ...state,
        googlePlace: {},
        googlePlaceLoading: false,
      }

    case LOAD_GOOGLE_PLACE_FULFILLED:
      return {
        ...state,
        googlePlace: action.payload,
        googlePlaceLoading: false,
      }

    case LOAD_GOOGLE_PLACE_DETAILS_REJECTED:
      return {
        ...state,
        googlePlaceDetails: {},
        googlePlaceLoading: false,
      }

    case LOAD_GOOGLE_PLACE_DETAILS_FULFILLED:
      return {
        ...state,
        googlePlaceDetails: action.payload,
        googlePlaceLoading: false,
      }

    case LOAD_STORE_PRODUCT_BY_ID_FULFILLED:
      return {
        ...state,
        productDetails: action.payload,
        isLoading: false,
      }

    case LOAD_USER_ORDERS_FULFILLED:
      const orders = state?.orders || []
      const newOrdersIds = action.payload.data?.map((item) => item.id)
      const difference = orders.filter((x) => !newOrdersIds.includes(x.id)).concat(action.payload.data)

      return {
        ...state,
        orders: difference,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }
    case LOAD_NEW_USER_ORDERS_FULFILLED:
      return {
        ...state,
        orders: action.payload.data,
        canLoadMore: action.payload.data?.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && action.payload.currentPage !== action.payload.totalPages,
        isLoading: false,
      }

    case LOAD_USER_ORDER_BY_ID_FULFILLED:
      return {
        ...state,
        orderDetails: action.payload,
        orderLoading: false,
      }

    case LOAD_USER_ORDER_DOCUMENTS_FULFILLED:
      return {
        ...state,
        orderDocuments: action.payload,
        documentsLoading: false,
      }

    case LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_FULFILLED:
      return {
        ...state,
        documentS3Location: action.payload,
        documentLocationLoading: false,
      }

    case LOAD_USER_SUBSCRIPTIONS_FULFILLED:
      return {
        ...state,
        subscriptions: action.payload,
        isLoading: false,
      }

    case LOAD_USER_SUBSCRIPTION_BY_ID_FULFILLED:
      return {
        ...state,
        subscriptionDetails: action.payload[0],
        subscriptionLoading: false,
      }

    case DELETE_USER_SUBSCRIPTION_PENDING:
    case RENEW_SUBSCRIPTION_PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        sucessMessage: null,
      }

    case DELETE_USER_SUBSCRIPTION_FULFILLED:
      let subscriptionsAfterDelete = state?.subscriptions || []

      if (action.meta.isTestDriveSubscription) {
        subscriptionsAfterDelete = subscriptionsAfterDelete.map((s) => (s.id === action.meta.id ? { ...s, status: "canceled" } : s))
      } else {
        subscriptionsAfterDelete = subscriptionsAfterDelete.map((s) => (s.id === action.meta.id ? { ...s, cancelAtPeriodEnd: true } : s))
      }

      return {
        ...state,
        subscriptions: subscriptionsAfterDelete,
        isLoading: false,
        successMessage: i18n.t("coltene_store.order_history.subscription-canceled"),
      }

    case RENEW_SUBSCRIPTION_FULFILLED:
      const subscriptionsAfterRenew = state?.subscriptions?.map((s) => (s.id === action.meta.id ? { ...s, cancelAtPeriodEnd: false } : s))

      return {
        ...state,
        subscriptions: subscriptionsAfterRenew,
        isLoading: false,
        successMessage: i18n.t("coltene_store.order_history.subscription-renewed"),
      }

    case DELETE_USER_SUBSCRIPTION_REJECTED:
    case RENEW_SUBSCRIPTION_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }

    case SET_IN_BASKET:
      const basket = state?.basket || {}
      if (action.payload.quantity > 0) {
        basket[action.payload.id] = {
          quantity: action.payload.quantity,
          id: action.payload.id,
          title: action.payload.title,
          imageUrl: action.payload.hasOwnProperty("imageUrl") ? action.payload.imageUrl : action.payload.images[0]?.url,
          description: action.payload.description,
          category: action.payload.category,
          prices: action.payload.prices,
          isDigital: action.payload.isDigital,
          subtitle: action.payload.subtitle,
        }
      } else {
        delete basket[action.payload.id]
      }
      return {
        ...state,
        basket: { ...basket },
      }

    case CLEAR_BASKET:
      return {
        ...state,
        basket: {},
      }

    case SET_CHECKOUT_LINK_FULFILLED:
      return {
        ...state,
        checkoutLink: action.payload.url,
        isShippingPageLoading: false,
      }

    case SET_CHECKOUT_SESSION:
      return {
        ...state,
        checkoutSession: action.payload,
      }

    case LOAD_USER_SUBSCRIPTION_DETAILS_FULFILLED:
      const subscriptions_details = state?.subscriptionsDetails || {}
      subscriptions_details[action.payload.id] = action.payload
      return {
        ...state,
        subscriptionsDetails: subscriptions_details,
        isLoading: false,
      }

    case LOAD_STORE_PRODUCTS_REJECTED:
    case LOAD_STORE_NEW_PRODUCTS_REJECTED:
    case LOAD_USER_ORDERS_REJECTED:
    case LOAD_NEW_USER_ORDERS_REJECTED:
    case LOAD_USER_SUBSCRIPTIONS_REJECTED:
    case LOAD_USER_SUBSCRIPTION_DETAILS_REJECTED:
    case LOAD_STORE_PRODUCT_BY_ID_REJECTED:
      return {
        ...state,
        isLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }

    case SET_CHECKOUT_LINK_REJECTED:
      return {
        ...state,
        isShippingPageLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }

    case LOAD_USER_ORDER_DOCUMENTS_REJECTED:
      return {
        ...state,
        documentsLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }

    case LOAD_USER_ORDER_DOCUMENT_S3_LOCATION_REJECTED:
      return {
        ...state,
        documentLocationLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }

    case LOAD_USER_ORDER_BY_ID_REJECTED:
      return {
        ...state,
        orderLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case LOAD_USER_SUBSCRIPTION_BY_ID_REJECTED:
      return {
        ...state,
        subscriptionLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case UPDATE_REFUND_ORDER:
      var refunded_orders = state?.orders.map((order) => (order.id === action.id ? { ...order, status: "Refund Requested" } : order))
      return {
        ...state,
        orders: refunded_orders,
      }

    case CLEAR_STORE_MESSAGES: {
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    }
    default:
      return state
  }
}
