import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Storage } from "aws-amplify"
import RequestJoinModal from "./RequestJoinModal"
import ConvertToOrganizationModal from "./ConvertToOrganizationModal"
import LoadingSpinner from "../../common/LoadingSpinner"
import SimpleButton from "../../common/SimpleButton"
import ConfirmationModal from "../../common/ConfirmationModal"
import ActionsMenu from "../../common/ActionsMenu"
import GenericTable from "../../common/GenericTable"
import ClickOutside from "../../common/ClickOutside"
import ExpandableText from "../../common/ExpandableText"
import { toast } from "../../common/Toast"
import EmptyPageResults from "../../common/EmptyPageResults"
import GoogleAddressInput from "../../common/GoogleAddressInput"
import CheckboxInput from "../../common/CheckboxInput"
import config from '../../../utils/config'
import { sendMail } from "../../../utils/requests/mqttPublishAPI"
import { organizationAdminModulesPermission } from "../../../utils/permissionValidation"
import { postCompanyLogo } from "../../../utils/requests/companyAPI"
import { getCurrentUserPermissions, validateCompanyName } from '../../../utils/requests/usersAPI'
import { useWindowSize } from "../../../libs/hooks"
import {
  loadUserCompany,
  loadCompanyLogo,
  removeCompanyLogo,
  clearUserCompanyMessages,
  leaveCompany,
  loadCompanyRequests,
  acceptCompanyRequest,
  rejectCompanyRequest,
  cancelCompanyRequest,
  resendCompanyRequest,
  updateBranchInformation,
} from "../../../actions/userCompany"
import { handleUpdateAuthedUser } from "../../../actions/authedUser"
import { handleSetCurrentUserPermissions } from "../../../actions/userPermissions"
import { CaretDownIcon, CompanyBuilding, DisabledIcon, MenuKebabVerticalBackgroundIcon, MenuKebabVerticalIcon, PendingIcon, PhoneIcon, PhotoIcon } from "../../../icons"
import { formatPhone } from "../../../utils/functions"
import { convertTimeWithTAndZ } from "../../../utils/filters/date"
import "./my-organization.scss"

const orderingFields = [
  {
    field: "lastModifyAt",
    order: "asc",
    key: "lastModifyAt_asc",
  },
  {
    field: "lastModifyAt",
    order: "desc",
    key: "lastModifyAt_desc",
  },
  {
    field: "companyName",
    order: "asc",
    key: "company_asc",
  },
  {
    field: "companyName",
    order: "desc",
    key: "company_desc",
  },
  {
    field: "status",
    order: "asc",
    key: "status_asc",
  },
  {
    field: "status",
    order: "desc",
    key: "status_desc",
  },
]

const MyOrganization = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const { 
    isCompanyAccount, 
    companyId,
    branchId,
    companyCountry,
    companyLogo,
  } = props
  const [companyLogoUrl, setCompanyLogoUrl] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [companyAddress, setCompanyAddress] = useState("")
  const [companyCity, setCompanyCity] = useState("")
  const [companyState, setCompanyState] = useState("")
  const [companyZip, setCompanyZip] = useState("")
  const [companyPhone, setCompanyPhone] = useState("")
  const [haveError, setHaveError] = useState({})
  const [showRequestJoinModal, setShowRequestJoinModal] = useState(false)
  const [showConvertToOrganizationModal, setShowConvertToOrganizationModal] = useState(false)
  const [showLeaveCompanyConfirmationModal, setShowLeaveCompanyConfirmationModal] = useState(false)
  const [requests, setRequests] = useState([])
  const [hasRequestedAlready, setHasRequestedAlready] = useState(false)
  const [orderBy, setOrderBy] = useState(null)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [expandedRequest, setExpandedRequest] = useState(0)
  const [showSpinner, setShowSpinner] = useState(null)
  const [hasCompanyInformationChanges, setHasCompanyInformationChanges] = useState(false)
  const [keepDentistDevices, setKeepDentistDevices] = useState(false)
  const isCompanyMember = companyId && props.companyStatus === "approved"
  const isCompanyAdmin = props.companyAdmin.toLowerCase() === props.currentUserEmail.toLowerCase() ? true : false
  const phonePattern = new RegExp(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g)

  useEffect(() => {
    props.actions.loadUserCompany()
  }, [props.actions])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearUserCompanyMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearUserCompanyMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    setCompanyName(props.companyName)
    setCompanyAddress(props.companyAddress)
    setCompanyCity(props.companyCity)
    setCompanyState(props.companyState)
    setCompanyZip(props.companyZip)
    setCompanyPhone(formatPhone(props.companyPhone))
  }, [props.companyName, props.companyAddress, props.companyCity, props.companyState, props.companyZip, props.companyPhone])

  useEffect(() => {
    if (companyId && branchId && !companyLogo) {
      props.actions.loadCompanyLogo(companyId, branchId)
    }
  }, [branchId, companyId])
  
  useEffect(() => { 
    const fetchLogo = async () => {
      Storage.configure({
        customPrefix: {
          public: "",
          protected: "",
          private: "",
        },
      })
      
      const imgSrc = await Storage.get(companyLogo)
      setCompanyLogoUrl(imgSrc)
    }
    
    if (companyLogo) {
      fetchLogo()

      return
    }

    setCompanyLogoUrl("")
  },[companyLogo])

  useEffect(() => {
    const mappedRequests = props.companyRequests.map(request => {
      const showCancel = request.requestType === "request" && (request.status === "requested" || request.status === "expired")
      const showAcceptReject = request.requestType === "invite" && request.status === "invited"
      const showResend = request.requestType === "request" && request.status === "expired"
    
      return {
        ...request,
        date: convertTimeWithTAndZ(request.lastModifyAt),
        company: request.companyName,
        email: request.requestType === "request" ? request.email : request.createdBy,
        requestType: request.requestType,
        status: request.status,
        actions: [
          ...(showAcceptReject 
            ? [
                {
                  label: t("labels.accept"),
                  isDisabled: isCompanyMember,
                  onActionClick: (e) => {
                    e.stopPropagation()
                    handleAcceptRequest(request.id, request.role?.name)
                  },
                },
                {
                  label: t("labels.reject"),
                  isDisabled: isCompanyMember,
                  onActionClick: (e) => {
                    e.stopPropagation()
                    handleRejectRequest(request.id)
                  },
                },
              ]
            : []
          ),
          ...(showResend 
            ? [
                {
                  label: t("labels.resend"),
                  onActionClick: (e) => {
                    e.stopPropagation()
                    handleResendRequest(request.id)
                  },
                },
              ] 
            : []
          ),
          ...(showCancel 
            ? [
                {
                  label: request.status === "requested" ? t("labels.cancel") : t("labels.remove"),
                  onActionClick: (e) => {
                    e.stopPropagation()
                    handleCancelRequest(request.id)
                  },
                },
              ] 
            : []
          ),
        ], 
      }
    }) || []

    setRequests(mappedRequests)
    setHasRequestedAlready(mappedRequests.some(request => request.requestType === "request"))
  }, [props.companyRequests])

  const fetchInvites = useCallback(async () => {
    let queryParams = {}

    if (orderBy) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    props.actions.loadCompanyRequests(queryParams)
  }, [orderBy])
  
  useEffect(() => {
    if (!isCompanyAccount) {
      fetchInvites()
    }
  }, [orderBy])
  
  useEffect(() => {
    const hasChanges = companyName !== props.companyName || 
      companyAddress !== props.companyAddress ||
      companyCity !== props.companyCity ||
      companyState !== props.companyState ||
      companyZip !== props.companyZip ||
      companyPhone !== formatPhone(props.companyPhone)

    setHasCompanyInformationChanges(hasChanges)
  }, [
    companyName,
    companyAddress,
    companyCity,
    companyState,
    companyZip,
    companyPhone,
    props.companyName,
    props.companyAddress,
    props.companyCity,
    props.companyState,
    props.companyZip,
    props.companyPhone,
  ])

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingField)
  }

  const handleAcceptRequest = (requestId, newRole) => {
    props.actions.acceptCompanyRequest(requestId)
      .then(async () => {
        props.actions.loadUserCompany()

        const currentUserRole = props.authedUser?.userGroup.split(/[_-]/)[0]
        const newRoleFormatted = newRole?.toLowerCase()?.replace(/ /g, "_")

        if (!newRoleFormatted || (currentUserRole === newRoleFormatted)) {
          props.actions.handleUpdateAuthedUser(
            { 
              companyAccountType: "organization_member",
            }
          )

          return
        }

        const permissions = await getCurrentUserPermissions()
        props.actions.handleSetCurrentUserPermissions(permissions)

        props.actions.handleUpdateAuthedUser(
          { 
            userGroup: newRoleFormatted,
            companyAccountType: "organization_member",
          }
        )
      })
  }
  
  const handleRejectRequest = (requestId) => {
    props.actions.rejectCompanyRequest(requestId)
  }

  const handleCancelRequest = (requestId) => {
    props.actions.cancelCompanyRequest(requestId)
  }

  const handleResendRequest = (requestId) => {
    props.actions.resendCompanyRequest(requestId)
  }

  const handleLeaveCompany = () => {
    const bodyParams = {
      ...(false && { keepDeviceWithMember: keepDentistDevices }),
    }

    props.actions.leaveCompany(bodyParams)
      .then(() => {
        props.actions.handleUpdateAuthedUser(
          { 
            companyAccountType: "individual",
          }
        )
      })
  }
  
  const handleRemoveAvatar = () => {
    props.actions.removeCompanyLogo(companyId, branchId)
  }
  
  const handleAddAvatar = async (event) => {
    try {
      const file = event?.target?.files[0]
      const reader = new FileReader()
      
      if (file?.size > 1 * 1024 * 1024) {
        toast.error(t("error.file_size_one_mb"))
        return
      }
      
      reader.onloadend = async () => {
        const base64String = reader.result?.split(',')[1]
        const bodyParams = {
          fileName: file?.name,
          fileContent: base64String
        }

        setShowSpinner(true)
        await postCompanyLogo(companyId, branchId, bodyParams)
        setShowSpinner(false)

        props.actions.loadCompanyLogo(companyId, branchId)
      }
      
      reader.readAsDataURL(file)
    } catch (error) {
      toast.error(t("error.something_wrong"))
    }
  }
  
  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }
  
  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    setOrderBy(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }
  
  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy !== null ? t(`company.${orderBy?.key}`) : t("events.select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )
  
  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy?.field ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`company.${orderingField.key}`)}
        </div>
      ))}
    </div>
  )

  const handleCancelCompanyInformationChanges = () => {
    setCompanyName(props.companyName)
    setCompanyAddress(props.companyAddress)
    setCompanyCity(props.companyCity)
    setCompanyState(props.companyState)
    setCompanyZip(props.companyZip)
    setCompanyPhone(formatPhone(props.companyPhone))
  }

  const isValidInput = () => {
    let errors = {}
    let isValid = true

    if (!companyName.trim()) {
      errors = { ...errors, name: true }
      isValid = false
    }

    if (!companyPhone.trim() || !phonePattern.test(companyPhone)) {
      errors = { ...errors, phone: true }
      isValid = false
    }

    if (!companyAddress.trim()) {
      errors = { ...errors, address: true }
      isValid = false
    }

    if (!companyCity.trim()) {
      errors = { ...errors, city: true }
      isValid = false
    }

    if (!companyState.trim()) {
      errors = { ...errors, stateProvince: true }
      isValid = false
    }

    if (!companyZip.trim()) {
      errors = { ...errors, zipCode: true }
      isValid = false
    }

    setHaveError(errors)
    return isValid
  }

  const handleUpdateBranchInformation = async () => {
    if (!isValidInput()) {
      return
    }

    const bodyParams = {
      branchName: companyName.trim(),
      email: props.companyAdmin,
      country: companyCountry,
      officeAddressOne: companyAddress.trim(),
      city: companyCity.trim(),
      stateProvinceRegion: companyState.trim(),
      zipPostalCode: companyZip.trim(),
      telephone: companyPhone.trim(),
    }

    props.actions.updateBranchInformation(bodyParams)
  }

  const handleRequestHelp = async () => {
    const emailTemplateVars = {
      email: `${props.authedUser?.email}`,
      company: `${props.authedUser?.company}`,
      firstname: `${props.authedUser?.firstname}`,
      lastname: `${props.authedUser?.lastname}`,
      phone: `${props.authedUser?.phone}`,
      country: `${props.authedUser?.country}`,
      language: `${props.authedUser?.language}`,
    }

    const templateName = `organization_registration_get_help_en_CA`
    const email = config.organizationReviewEmailTemplate.email

    try {
      await sendMail(
        email,
        templateName,
        emailTemplateVars
      )

      toast.success(t("register.email_sent"))
    } catch (error) {
      toast.error(t("error.something_wrong"))
    }
  }

  const handleCheckCompanyExists = async () => {
    if (companyName.trim().length > 0 && companyName.trim() !== props.companyName) {
      setShowSpinner(true)

      const queryParams = { name: companyName.trim() }
      const companyValidationResponse = await validateCompanyName(queryParams)

      setShowSpinner(false)

      if (companyValidationResponse.isCompanyExists) {
        toast.error(t("error.company_name_exists_support"))

        setHaveError(prevError => ({
          ...prevError,
          name:false,
          companyExists: true
        }))

        
      }

      return
    }

    setHaveError(prevError => ({
      ...prevError,
      name: false,
      companyExists: false
    }))
  }

  return (
    <div className="my-company-wrapper">
      {!isCompanyMember && (
        <div className="section-wrapper locked-section">
          {!showSpinner && (
            <>
              <div className="locked-section-title">
                {!hasRequestedAlready && !isCompanyAccount ? t("account.get_access") : t("account.pending_approval")}
              </div>
              <div className="locked-section-subtitle">
                {!hasRequestedAlready && !isCompanyAccount && t("account.get_access_disclaimer")}
                {hasRequestedAlready && t("account.get_access_requeste_disclaimer")}
                {isCompanyAccount &&
                  <div>
                    {t("account.get_access_converted_disclaimer")}
                    <span className="link-action" onClick={() => handleRequestHelp()}>{t("account.get_access_converted_request_help")}</span>
                  </div>
                }
              </div>
              {!hasRequestedAlready && !isCompanyAccount && (
                <div className="locked-section-buttons-wrapper">
                  <SimpleButton 
                    className="action-button"
                    onClick={() => setShowRequestJoinModal(true)}
                  >
                    {t("account.request_to_join")}
                  </SimpleButton>
                  <SimpleButton 
                    className="action-button"
                    onClick={() => setShowConvertToOrganizationModal(true)}
                  >
                    {t("account.convert_to_organization")}
                  </SimpleButton>
                </div>)
              }
            </>
          )}
        </div>
      )}
      {isCompanyMember && (
        <div className="section-wrapper">
          <div className="wrapper-header">
            <p className="wrapper-title">{t("account.organization_details")}</p>
            {!isCompanyAccount && !isCompanyAdmin && (
              <div className="buttons-wrapper">
                <SimpleButton 
                  className="action-button"
                  onClick={() => setShowLeaveCompanyConfirmationModal(true)}
                >
                  {t("account.leave_organization")}
                </SimpleButton>
              </div>)
            }
          </div>
          <div className="section">
            <div className="section-title">
              <p>{t("account.organization_logo")}</p>
              <p className="disclaimer">{t("account.photo_discalimer")}</p>
            </div>
            <div className="section-content avatar">
              <div className="avatar-wrapper">
                <label className="file-input-wrapper" htmlFor="file-input">
                  <div className="avatar-img">
                    {companyLogoUrl ? (
                      <img className="image" src={companyLogoUrl} alt="avatar" />
                    ) : (
                      <div className="avatar-initials">
                      </div>
                    )}
                  </div>
                  {isCompanyAccount && isCompanyAdmin && <PhotoIcon />}
                </label>
                {isCompanyAccount && isCompanyAdmin && ( 
                  <input
                    className="file-input"
                    id="file-input"
                    onChange={handleAddAvatar}
                    type="file"
                    accept="image/*"
                  />)
                }
              </div>
              {isCompanyAccount && isCompanyAdmin && (
                <SimpleButton
                  className="action-button remove-image-button"
                  onClick={() => handleRemoveAvatar()}
                  disabled={!companyLogoUrl || showSpinner}
                  requiredPermission={organizationAdminModulesPermission}
                >
                  {t("account.remove")}
                </SimpleButton>)
              }
            </div>
          </div>
          <div className="separator" />
          <div className="section">
            <div className="section-title">
              {t("account.organization_information")}
            </div>
            <div className="section-content">
              <div className={"input-item-wrapper" + (haveError["name"] || haveError["companyExists"] ? " has-error": "")}>
                <div className="input-item-icon">
                  <CompanyBuilding />
                </div>
                <input 
                  type="text" 
                  name="company"
                  className="input-item"
                  value={companyName}
                  placeholder={`${t("account.company")}*`}
                  onChange={(e) => setCompanyName(e.target.value)}
                  onBlur={handleCheckCompanyExists}
                  readOnly={!isCompanyAccount && !isCompanyAdmin}
                />
                {haveError["name"] && <p className="error-message">{t("account.organization_name_required")}</p>}
                {haveError["companyExists"] && <p className="error-message">{t("error.company_name_exists")}</p>}
              </div>
            </div>
          </div>
          <div className="section">
            <div className="section-title empty"/>
            <div className="section-content">
              <GoogleAddressInput
                country={companyCountry}
                address={companyAddress}
                handleSetAddress={(e) => setCompanyAddress(e)}
                city={companyCity}
                handleSetCity={(e) => setCompanyCity(e)}
                stateProvince={companyState}
                handleSetStateProvince={(e) => setCompanyState(e)}
                zipCode={companyZip}
                handleSetZipCode={(e) => setCompanyZip(e)}
                withIcons
                withoutLabels
                readOnly={!isCompanyAccount && !isCompanyAdmin}
                errors={haveError}
                withErrorLabels
                withRequiredPlaceholder
              />
            </div>
          </div>
          <div className="section">
            <div className="section-title empty"/>
            <div className="section-content without-margin-top">
              <div className={"input-item-wrapper" + (haveError["phone"] ? " has-error": "")}>
                <div className="input-item-icon">
                  <PhoneIcon />
                </div>
                <input 
                  type="text" 
                  name="company"
                  className="input-item"
                  value={formatPhone(companyPhone)}
                  placeholder={`${t("account.phone")}*`}
                  onChange={(e) => setCompanyPhone(e.target.value)}
                  readOnly={!isCompanyAccount && !isCompanyAdmin}
                />
                {haveError["phone"] && <p className="error-message">{t("error.phone")}</p>}
              </div>
            </div>
          </div>
          {isCompanyAccount && isCompanyAdmin && (
            <div className="section full-width">
              <div className="section-title empty"/>
              <div className="section-content buttons">
                <SimpleButton
                  className="cancel-button"
                  disabled={!hasCompanyInformationChanges}
                  onClick={handleCancelCompanyInformationChanges}
                >
                    {t("labels.cancel")}
                </SimpleButton>
                <SimpleButton
                  className="submit-button"
                  disabled={!hasCompanyInformationChanges || showSpinner}
                  onClick={handleUpdateBranchInformation}
                  requiredPermission={organizationAdminModulesPermission}
                >
                  {t("labels.save")}
                </SimpleButton>
              </div>
            </div>)
          }
        </div>)
      }
      
      {!isCompanyAccount && !showSpinner && (
        <div className="section-wrapper">
          <div className="wrapper-header">
            <p className="wrapper-title">{t("company.my_requests")}</p>
          </div>
          {
            <div>
              {!isMobile && requests.length > 0 && (
                <>
                  <GenericTable
                    data={requests}
                    headers={[
                      {
                        title: t("company.date"),
                        orderKey: "lastModifyAt",
                      },
                      {
                        title: t("company.organization"),
                        orderKey: "companyName",
                      },
                      {
                        title: t("company.email"),
                      },
                      {
                        title: t("company.request-type"),
                      },
                      {
                        title: t("company.status"),
                        orderKey: "status",
                      },
                      {
                        title: "",
                      },
                    ]}
                    keys={[
                      "date",
                      "company", 
                      "email", 
                      "requestType", 
                      "status", 
                      "action"
                    ]}
                    keyRenderer={{
                      createdBy: (item) => {
                        return (
                          <div className="email-wrapper">
                            <ExpandableText text={item.email} />
                          </div>
                        )
                      },
                      requestType: (item) => {
                        return <>{t(`dealer-portal.${item.requestType}`)}</>
                      },
                      status: (item) => {
                        return (
                          <div className="status-wrapper">
                            <div className={item.status === "expired" ? "status-inactive" : "status-pending"}>
                              {item.status === "expired" ? <DisabledIcon /> : <PendingIcon />}
                              <p>{t(`status.${item.status}`)}</p>
                            </div>
                          </div>
                        )
                      },
                      action: (item) => {
                        return (
                          <div
                            className="table-actions-wrapper"
                            onClick={(e) => {
                              e.stopPropagation()
                              toggleShowActionsMenu(item.id)
                            }}
                          >
                            {showActionsMenu === item.id ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                            {showActionsMenu === item.id && (
                              <ActionsMenu setHideMenu={() => setShowActionsMenu(null)} actions={item.actions} isLoading={showSpinner} />
                            )}
                          </div>
                        )
                      },
                    }}
                    activeSort={orderBy}
                    onSort={(orderingField) => handleSort(orderingField)}
                    isLoading={showSpinner}
                  />
                </>
              )}
              {isMobile && requests.length > 0 && (
                <>
                  <div className="order-by-mobile-wrapper">
                    <ClickOutside 
                      itemRef="click-outside-wrapper" 
                      eventItem={selectOrderByOptionsInput} 
                      toDisplayItem={selectOrderByDropdownOptions} 
                    />
                  </div>
                  <div className="cards-container">
                    {requests.map((item, rowIndex) => {
                      return (
                        <div
                          key={rowIndex}
                          className={"card" + (expandedRequest === rowIndex ? " with-padding-bottom" : "")}
                          onClick={() => setExpandedRequest(rowIndex)}
                        >
                          <div className={"card-item"}>
                            <div className="card-item-title">{t("company.organization")}</div>
                            <div className={"card-item-body"}>
                              <div>{item.company}</div>
                              <div
                                className="card-actions"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setShowActionsMenu(rowIndex)
                                }}
                              >
                                {showActionsMenu === rowIndex ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                                {showActionsMenu === rowIndex && (
                                  <ActionsMenu setHideMenu={() => setShowActionsMenu(null)} actions={item.actions} isLoading={showSpinner} />
                                )}
                              </div>
                            </div>
                          </div>
                          {expandedRequest === rowIndex && (
                            <>
                              <div className="card-item">
                                <div className="card-item-title">{t("company.date")}</div>
                                <div className="card-item-body">{item.date}</div>
                              </div>
                              <div className="card-item">
                                <div className="card-item-title">{t("company.email")}</div>
                                <div className="card-item-body">{item.email}</div>
                              </div>
                              <div className="card-item">
                                <div className="card-item-title">{t("company.request-type")}</div>
                                <div className="card-item-body">{t(`dealer-portal.${item.requestType}`)}</div>
                              </div>
                              <div className="card-item">
                                <div className="card-item-title">{t("company.status")}</div>
                                <div className="card-item-body">
                                  <div className={item.status === "expired" ? "status-inactive" : "status-pending"}>
                                    <p>{t(`status.${item.status}`)}</p>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {expandedRequest !== rowIndex && (
                            <div className="expander-wrapper">
                              <CaretDownIcon />
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
          }
          {!showSpinner && requests.length === 0 && (
            <EmptyPageResults
              className="empty-page-results"
              title={t("company.no-requests-found")} 
              subtitle={t("company.no-requests-found-extra")}
            />
          )}
        </div>)
      }
      {showRequestJoinModal && (
        <RequestJoinModal
          handleClose={() => setShowRequestJoinModal(false)}
          fetchInvites={fetchInvites}
        />
      )}
      {showConvertToOrganizationModal && (
        <ConvertToOrganizationModal 
          closeModal={() => setShowConvertToOrganizationModal(false)}/>
      )}
      {showLeaveCompanyConfirmationModal && (
        <ConfirmationModal
          className="leave-company-confirmation"
          onToggle={() => setShowLeaveCompanyConfirmationModal(false)}
          message={t("account.leave_organization_confirmation")}
          acceptButtonText={t("account.leave")}
          onCancel={() => setShowLeaveCompanyConfirmationModal(false)}
          onAccept={() => {
            handleLeaveCompany()
            setShowLeaveCompanyConfirmationModal(false)
          }}
          isWarning
        >
          {/*A.S: To be hidden until requirement is established */}
          {false && (
            <div className="leave-confirmation-wrapper">
              <div className="disclaimer">
                <p>
                  {t("account.keep_devices_disclaimer")}
                </p>
              </div>
              <CheckboxInput
                checked={keepDentistDevices}
                text={t("account.keep_devices")}
                onChange={() => setKeepDentistDevices(!keepDentistDevices)}
              />
            </div>)
          }
        </ConfirmationModal>
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function stateToProps({ authedUser, userCompany }) {
  return {
    authedUser,
    companyId: userCompany?.companyId,
    branchId: userCompany?.branchId,
    companyName: userCompany?.companyName || "",
    companyAddress: userCompany?.companyAddress || "",
    companyCity: userCompany?.companyCity || "",
    companyState: userCompany?.companyState || "",
    companyZip: userCompany?.companyZip || "",
    companyCountry: userCompany?.companyCountry || "",
    companyAdmin: userCompany?.companyAdmin || "",
    companyStatus: userCompany?.companyStatus || "",
    companyPhone: userCompany?.companyPhone || "",
    companyLogo: userCompany?.companyLogo,
    errorMessage: userCompany?.errorMessage,
    successMessage: userCompany?.successMessage,
    isLoading: userCompany?.isLoading,
    isCompanyAccount: authedUser?.companyAccountType === "organization_admin" || false,
    currentUserEmail: authedUser?.email || "",
    currentUserGroup: authedUser?.userGroup || "",
    companyRequests: userCompany?.companyRequests || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadUserCompany,
        loadCompanyLogo,
        loadCompanyRequests,
        removeCompanyLogo,
        leaveCompany,
        acceptCompanyRequest,
        rejectCompanyRequest,
        cancelCompanyRequest,
        resendCompanyRequest,
        updateBranchInformation,
        clearUserCompanyMessages,
        handleUpdateAuthedUser,
        handleSetCurrentUserPermissions,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(MyOrganization)
